import React, { useState, useEffect } from "react";
import { Input, message, Spin } from "antd";
import { Button, Image, Toast } from "antd-mobile";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import "./index.less";
import BuildPay from "../../../components/Model/BuildPay";
import SignModal from "../../../components/Model/SignModal";
import Withdrawal from "../../../components/Model/Withdrawal";
import Http from "../../../utils/Http";
import { setToken } from "../../../store/app";
import { store } from "../../../store";
import { log } from "@craco/craco/lib/logger";

const idImg = require("./assets/icon_my_id@2x.png");
const zuanshi = require("./assets/icon_main_chongzhi_zuanhsi@2x.png");
const shendou = require("./assets/icon_main_wallet_shendou@2x.png");
const alipay = require("./assets/icon_main_zhifu_alipay@2x.png");
const wxpay = require("./assets/icon_main_zhifu_wchat@2x.png");
const black = require("./assets/icon_nav_black@2x.png");
const Unionpay = require("./assets/Unionpay.png");

const Withdraw = (props) => {
  const buildRef = React.useRef();
  const signRef = React.useRef();
  const withdrawalRef = React.useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [tixian, setTixian] = useState({ data: 0 });
  //删除路由传值
  // const history = useHistory();
  //提现列表
  const [items, setItems] = useState([]);
  const [gongmao, setGongmao] = useState(null);
  const [amount, setAmount] = useState("");
  //获取路由地址
  const [idcards, setIdcard] = useState(null);
  const [trueId, setTrueId] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const idcard = params.get("idcard");
  const name = params.get("name");
  const ids = params.get("ids");
  const id = params.get("id");
  const category = params.get("category") || "";
  //是否支持银行卡
  const [showBank, setShowBank] = useState(0);
  //起提与最大金额
  const [bankmoneymax, setBankmoneymax] = useState("1000");
  const [bankmoneymin, setBankmoneymin] = useState("1000");
  const [alipaymoneymin, setAlipaymoneymin] = useState("1000");

  const [types, setTypes] = useState(1);

  const getUserInfo = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/userinfo",
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setData(res.data);
      }
    } catch (e) {}
  };

  const getGongmaoInfo = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/gongmao",
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setGongmao(res.data);
      }
    } catch (e) {}
  };

  const getTixian = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/tixian",
        {
          data_type: category,
        },
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setTixian(res.data);
      }
    } catch (e) {}
  };

  const getAccountList = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/accountList",
        {
          category: category,
        },
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setItems(res.data);
      }
    } catch (e) {}
  };
  useEffect(() => {
    inquireId();
    // console.log("items:", items);
  }, [items]);
  const showGongmao = (g) => {
    if (g === null) {
      return;
    }

    if (g.status === 0) {
      return (
        <a style={{ marginLeft: 10, color: "#e35b5a" }} href={g.url}>
          点击签约
        </a>
      );
    }

    if (g.status === 2) {
      return <div style={{ marginLeft: 10, color: "#ff9900" }}>签约审核中</div>;
    }
    if (g.status === 3) {
      return <div style={{ marginLeft: 10, color: "#19be6b" }}>已签约</div>;
    }
    return <div></div>;
  };

  const submit = async () => {
    if (loading) {
      return;
    }
    try {
      const res = await Http.postForm("/api/v1/wechat_public/withdrawF", {
        // const res = await Http.postForm("/api/v1/wechat_public/withdraw", {
        get_amount: amount,
        data_type: category,
        id: trueId,
      });
      if (res.status === 10000) {
        setAmount("");
        Toast.show("提现申请已提交");
        getUserInfo();
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    } finally {
      setLoading(false);
    }
  };
  //查找身份证是否存在
  const inquireId = async () => {
    const foudItem = items.find((item) => item.id_number == idcard);
    const foudItemid = items.find((item) => item.id == id);
    if (foudItem && foudItemid) {
      setIdcard(ids);
      setTrueId(id);
    } else {
      setIdcard(null);
      setTrueId(null);
    }
  };
  //字典配置项目 是否支持银行卡
  const typeInspect = async () => {
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_bank_card_switch",
      });
      if (res.status === 10000) {
        setShowBank(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
  };
  //银行卡起提金额
  const getbankMoney = async () => {
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_cash_ withdrawal_ amount",
      });
      if (res.status === 10000) {
        setBankmoneymax(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_min_payouts_limit",
      });
      if (res.status === 10000) {
        setBankmoneymin(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
    // try {
    //   const res = await Http.postForm("/api/v1/common/getKeyConfig", {
    //     key: "business_ali_max_payouts_limit",
    //   });
    //   if (res.status === 10000) {
    //     setAlipaymoneymax(res.data);
    //   } else {
    //     Toast.show(res.message);
    //   }
    // } catch (e) {
    //   Toast.show(e.message);
    // }
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_ali_min_payouts_limit",
      });
      if (res.status === 10000) {
        setAlipaymoneymin(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
  };
  const accountListUp = () => {
    navigate("/accountList?type=" + types);
  };
  useEffect(() => {
    getUserInfo();
    // getGongmaoInfo();
    getTixian();
    getAccountList();
    typeInspect();
    getbankMoney();
    if (category == 1) {
      setTypes(1);
    } else if (category == 2) {
      setTypes(2);
    }
  }, []);

  return (
    <div className="money-page">
      <BuildPay
        onFinish={() => {
          getUserInfo();
        }}
        ref={buildRef}
      />
      <SignModal
        onFinish={() => {
          getUserInfo();
        }}
        ref={signRef}
      />
      <Withdrawal
        onSubmit={() => {
          submit();
        }}
        amount={amount}
        tixian={tixian}
        account={data?.user_info?.ali_account}
        ref={withdrawalRef}
      />
      <div className="money-header">
        <div className="money-left">
          <Image
            src={data?.user_info?.avatar}
            width={50}
            height={50}
            style={{ borderRadius: 35, marginRight: 8 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: 50,
            }}
          >
            <div>{data?.user_info?.nickname}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                style={{ marginRight: 6 }}
                width={20}
                height={14}
                src={idImg}
              />
              <div style={{ color: "#979797", fontSize: 13, lineHeight: 1 }}>
                {data?.user_info?.uid}
              </div>
            </div>
          </div>
          {/* <Button
            style={{
              "--background-color": "#FFFFFF",
              "--border-color": "orange",
              marginLeft: 24,
            }}
            shape="rounded"
            size="small"
            danger
            onClick={() => {
              signRef.current.show();
            }}
          >
            <div style={{ color: "orange", fontSize: 13, width: 54 }}>
              我的签约
            </div>
          </Button>
          {showGongmao(gongmao)} */}
        </div>
        <Button
          style={{
            "--background-color": "#FFFFFF",
            "--border-color": "#FF850E",
          }}
          shape="rounded"
          size="small"
          danger
          onClick={() => {
            store.dispatch(setToken(null));
            navigate("/");
          }}
        >
          <div style={{ color: "#FF850E", fontSize: 13, width: 54 }}>
            退出登录
          </div>
        </Button>
      </div>
      <div className="money-center">
        <div className="money-center-top">
          <div className="money-center-top-item">
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                marginTop: 17,
                marginBottom: 8,
              }}
            >
              <Image
                style={{ marginRight: 8 }}
                width={24}
                height={24}
                src={zuanshi}
              />
              <div style={{ fontSize: 16 }}>当前钻石</div>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "left",
                marginBottom: 16,
                color: "#000000",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {data?.zuanshi_balance ?? 0}
            </div>
          </div>
          <div className="money-center-top-item">
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                marginTop: 17,
                marginBottom: 8,
              }}
            >
              <Image
                style={{ marginRight: 8 }}
                width={24}
                height={24}
                src={shendou}
              />
              <div style={{ fontSize: 16 }}>折算余额</div>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "left",
                marginBottom: 16,
                color: "#000000",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {data?.rmb_balance ?? 0}
            </div>
          </div>
        </div>
        <div className="money-center-way">
          <div
            style={{
              paddingTop: 16,
              fontSize: 14,
              marginBottom: 16,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>选择提现到</div>
            <div
              onClick={() => {
                accountListUp();
              }}
            >
              选择账号 >
            </div>
          </div>

          <div className="money-way-list">
            {/* category == "" ? || category == "1" */}
            <div
              className={types === 1 ? "money-way-item" : "money-way-item2"}
              onClick={() => {
                // navigate("/accountList?type=1");
                setTypes(1);
                // accountListUp();
              }}
            >
              {/* <div>默认账户</div> */}
              <Image
                style={{ marginRight: 8 }}
                src={alipay}
                width={24}
                height={24}
              />
              <span style={{ fontSize: 16, fontWeight: 500, color: "#050303" }}>
                支付宝
              </span>
            </div>

            {/* && (category == "2" || category == "") */}
            {showBank == 1 ? (
              <div
                className={types === 2 ? "money-way-item" : "money-way-item2"}
                onClick={() => {
                  // navigate("/accountList?type=2");
                  setTypes(2);
                  // accountListUp();
                }}
              >
                {/* <div>默认账户</div> */}
                <Image
                  style={{ marginRight: 8 }}
                  src={Unionpay}
                  width={24}
                  height={24}
                />
                <span
                  style={{ fontSize: 16, fontWeight: 500, color: "#050303" }}
                >
                  银行卡
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          {
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#F03350",
                paddingBottom: 12,
                fontSize: 13,
              }}
            >
              <span style={{ color: "#343537" }}>
                提现至:
                {category == 1 ? "支付宝 " : category == 2 ? "银行卡 " : ""}
                {idcards || " 请选择提现账号"}
                {/* {data?.user_info?.ali_account} */}
              </span>
              {/* <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  buildRef.current.show();
                }}
              >
                <div>更改</div>
                <Image
                  style={{ marginLeft: 4 }}
                  src={black}
                  width={14}
                  height={14}
                />
              </div> */}
            </div>
          }
        </div>
        <div className="moeny-center-num">
          <div style={{ paddingTop: 16, fontSize: 14, marginBottom: 16 }}>
            提现金额
          </div>
          <div className="money-center-input">
            <Input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              bordered={false}
              placeholder="输入提现金额"
              prefix={
                <div style={{ fontSize: 16, color: "#343537", lineHeight: 1 }}>
                  ￥
                </div>
              }
              type="number"
              style={{ fontSize: 10, height: 48, borderRadius: 8 }}
            />
          </div>
        </div>
        <Button
          style={{
            height: 48,
            borderRadius: 8,
            backgroundColor: "#FF850E",
            marginTop: 22,
            marginBottom: 16,
          }}
          block
          onClick={() => {
            if (amount < 100) {
              Toast.show("提现金额需要大于100");
              return;
            }
            if (idcards === null) {
              Toast.show("请先选择提现账号");
              return;
            }
            withdrawalRef.current.show();
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {loading && <Spin />}
            提现
          </div>
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
          onClick={() => {
            navigate("/withdrawalList");
          }}
        >
          <span style={{ marginRight: 4, color: "#FF850E" }}>提现明细</span>
          <Image src={black} width={14} height={14} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            paddingTop: 19,
            paddingBottom: 20,
          }}
        >
          <span style={{ color: "#343537", fontSize: 12 }}>提现须知:</span>
          <div
            style={{ color: "#979797", fontSize: 12 }}
            dangerouslySetInnerHTML={{ __html: bankmoneymax }}
          />
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
